<template>
  <div class="report-final-payment">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <navigator :items="navigate_items"></navigator>
        </div>
        <div class="col-12">
          <div class="card card-shadow">
            <div class="card-body">
              <h4 class="mb-4">Relatório de Parcelas Finais (36/36)</h4>
              <div class="d-flex justify-content-between align-items-center mb-4">
                <div class="d-flex align-items-center">
                  <input
                    type="month"
                    class="form-control me-2"
                    v-model="selectedMonth"
                    :max="currentDate"
                  />
                  <button
                    class="btn btn-secondary"
                    v-on:click="fetchData()"
                  >
                    Filtrar
                  </button>
                </div>
                <button
                  class="btn btn-primary"
                  v-on:click="createShipment()"
                  :disabled="clients.length === 0"
                >
                  Nova Remessa
                </button>
              </div>

              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Parcela</th>
                      <th>Data Pagamento</th>
                      <th>Unidade Consumidora</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in clients" :key="item.id">
                      <td>{{ item.name }}</td>
                      <td>{{ item.parcela }}</td>
                      <td>{{ formatDate(item.payment_date) }}</td>
                      <td>{{ item.consumer_unit }}</td>
                      <td>{{ formatMoney(item.donation_transfer) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="no-data" v-if="clients.length == 0">
                <span>Sem dados para o período selecionado</span>
              </div>

              <b-pagination
                v-if="totalRows > 0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                class="mt-3"
                align="right"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helper from "@/imports/Helpers";
import axios from "@/api/axios";
import moment from "moment";
import { bus } from "@/main";

export default {
  name: "report-final-payment",
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      clients: [],
      selectedMonth: moment().format("YYYY-MM"),
      currentDate: moment().format("YYYY-MM"),
      isLoading: false,
      navigate_items: [
        {
          text: "Relatório Parcelas Finais",
          active: true,
        },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      const params = {
        month: moment(this.selectedMonth).format('YYYY-MM'),
        page: this.currentPage,
        per_page: this.perPage
      };

      axios.get('/api/reports/final-payments', { params })
        .then(response => {
          this.clients = response.data.data;
          this.totalRows = response.data.total;
          this.currentPage = response.data.current_page;
          this.isLoading = false;
        })
        .catch(error => {
          console.error("Erro ao buscar dados:", error);
          this.isLoading = false;
          bus.$emit("showMessage", {
            message: (error.response && error.response.data && error.response.data.error) || "Erro ao carregar os dados",
            type: "error"
          });
        });
    },
    async createShipment() {
      try {
        // Primeiro, buscar todos os clientes do mês selecionado
        const response = await axios.get('/api/reports/final-payments', {
          params: {
            month: moment(this.selectedMonth).format('YYYY-MM'),
            per_page: 999999 // Um número grande para pegar todos
          }
        });

        const selectedClients = response.data.data.map(client => ({
          id: client.id,
          name: client.name,
          consumer_unit: client.consumer_unit,
          donation_transfer: client.donation_transfer,
          payment_date: client.payment_date
        }));

        // Criar a remessa com todos os clientes
        const token = localStorage.getItem('auth.token');
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };

        const shipmentResponse = await axios.post('/api/shipments', { selectedClients }, config);

        // Baixar o arquivo
        const downloadResponse = await axios.get(`/api/download-shipment/${shipmentResponse.data.id}`, { 
          responseType: 'blob'
        });
        
        // Criar URL do blob e iniciar download
        const url = window.URL.createObjectURL(new Blob([downloadResponse.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${shipmentResponse.data.name}.txt`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        // Mostrar mensagem de sucesso
        bus.$emit("showMessage", {
          message: "Remessa criada com sucesso!",
          type: "success"
        });

        // Redirecionar para a listagem de remessas
        this.$router.push('/dashboard/shipments');
      } catch (error) {
        bus.$emit("showMessage", {
          message: (error.response && error.response.data && error.response.data.error) || "Erro ao criar remessa",
          type: "error"
        });
      }
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatMoney(value) {
      return helper.formatMoney(value);
    }
  },
  watch: {
    currentPage(newPage) {
      this.fetchData();
    }
  }
};
</script>

<style lang="scss">
.report-final-payment {
  .table {
    margin-bottom: 0;
  }
  
  .pagination {
    justify-content: flex-end;
    margin-bottom: 0;
  }
}
</style>